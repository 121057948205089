import { useEffect, useState } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';
import { useAuth0Token } from './useAuth0Token';

type Props = {
  url: string;
  useToken?: boolean;
};

export const useFetch = <DataType>({ url, useToken = false }: Props) => {
  const [data, setData] = useState<DataType>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { simpleToken } = useAuth0Token();
  const { apimContentHub } = useSelector((state) => state.application);

  useEffect(() => {
    if (url) {
      setIsLoading(true);
      try {
        const headers: {
          'Ocp-Apim-Subscription-Key': string;
          Authorization?: string;
        } = {
          'Ocp-Apim-Subscription-Key': apimContentHub,
        };
        if (useToken && simpleToken) {
          headers.Authorization = `Bearer ${simpleToken}`;
        }
        axios
          .get<DataType>(url, {
            headers,
          })
          .then((res) => {
            setIsLoading(false);
            setData(res.data);
            setError(false);
          });
      } catch (e) {
        setIsLoading(false);
        setError(true);
      }
    }
  }, [url, apimContentHub, simpleToken, useToken]);

  return { data, isLoading, error };
};
